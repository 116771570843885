import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { useMenuLinks } from "../hooks/useMenuLinks"
import Logo from "./Logo"
import GoTop from "./GoTop"
import { getVariables } from "../lib/utils"

const Footer = () => {
    const variables = getVariables()

    const { compareSidebarInfoNode, flagImage, country } = useMenuLinks()

    const image = getImage(flagImage)
    const currentDate = new Date()

    return (
        <footer className="flex flex-col flex-wrap">
            <GoTop />
            
            <div className="bg-zone-5 p-6 pb-0">
                <div className="container mx-auto text-zone-4 flex flex-wrap justify-around font-grotesk">
                    <div className="flex-100 mb-6 lg:flex-1.5">
                        <Logo />

                        <div className="inner-info" dangerouslySetInnerHTML={{ __html: compareSidebarInfoNode.childMarkdownRemark.html }} />

                        <div className="mb-6">
                            <a href="https://www.facebook.com/matresseszone" key="footer-social-facebook" target="_blank" rel="noreferrer" className="inline-block mr-4 hover:opacity-80 text-zone-3" title="Mattress zone facebook page">
                                <svg width="17" height="17" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                            </a>
                            <a href="https://twitter.com/MattressesZone" key="footer-social-twitter" target="_blank" rel="noreferrer" className="inline-block mr-4 hover:opacity-80 text-zone-3" title="Mattress zone twitter page">
                                <svg width="17" height="17" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                            </a>
                            <a href="https://www.instagram.com/mattresseszone/" key="footer-social-instagram" target="_blank" rel="noreferrer" className="inline-block mr-4 hover:opacity-80 text-zone-3" title="Mattress zone instagram page">
                                <svg width="17" height="17" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                            </a>
                            <a href="https://linkedin.com/company/mattresszone" key="footer-social-linkedin" target="_blank" rel="noreferrer" className="inline-block hover:opacity-80 text-zone-3" title="Mattress zone linkedin page">
                            <svg width="17" height="17" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                            </a>
                        </div>
                    </div>

                    <div className="flex-1 mb-6 text-center">
                        <h4 className="text-lg mb-2 font-bold uppercase">{variables.guides}</h4>
                        <ul>
                            <li className="mb-1"><Link to="/" className="text-zone-2 capitalize underline hover:no-underline">{variables.mattress}</Link></li>
                            <li className="mb-1 text-zone-2 capitalize underline hover:no-underline"><Link to={`/${variables.mattresses}`}>{variables.compare}</Link></li>
                            {/*
                            <li><Link to="/reviews">{variables.reviews}</Link></li>
                            <li><Link to="/">Deals</Link></li>
                            */}
                        </ul>
                    </div>

                    <div className="flex-1 mb-6 text-center">
                        <h4 className="text-lg mb-2 font-bold uppercase">{variables.company}</h4>
                        <ul>
                            <li className="mb-1"><a href="https://mattress.zone/about-us" className="text-zone-2 capitalize underline hover:no-underline">{variables.about_us}</a></li>
                            <li className="mb-1"><a href="https://mattress.zone/#contact" className="text-zone-2 capitalize underline hover:no-underline" target="_blank" rel="noreferrer">{variables.contact}</a></li>
                            <li className="mb-1"><a href="https://mattress.zone/cookie-and-privacy-policy" className="text-zone-2 capitalize underline hover:no-underline" target="_blank" rel="noreferrer">{variables.privacy}</a></li>
                        </ul>
                    </div>

                    <div className="flex-1 mb-6 text-center">
                        <h4 className="text-lg mb-2 font-bold uppercase">{variables.region}</h4>
                        <ul>
                            <li className="mb-1">
                                <a href="https://mattress.zone/" className="flex justify-center items-center text-zone-2" target="_blank" rel="noreferrer">
                                    <GatsbyImage image={image} alt={country.countryName} className="mr-2 border border-zone-1 rounded-full" />
                                    <span className="mr-3">{country.countryName}</span>
                                    <span className="relative transform -rotate-90">&#10094;</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="bg-zone-13 text-zone-4 text-center p-6 text-base font-signika">
                Copyright © {currentDate.getFullYear()} Mattress.zone
            </div>
        </footer>
    )
}

export default Footer
